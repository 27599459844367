<template>
    <div v-if="images && images.length" >
        <ul v-viewer>
            <li v-for="item in images" :key="item.thumb" class="item-group">
                <img
                    v-lazy="item+'?x-image-process=style/love.png'"
                    :data-preview="item+'?x-image-process=style/love.png'" class="preview-img"  />
                <i class="banner-bg" :style="{'backgroundImage':'url('+ item +'?x-image-process=style/thumb.png)'}"></i>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/common/js/directives/useViewer';

export default {
    name: 'ProjectStoryImages',
    data() {
        return {};
    },
    computed: {
        ...mapState(['images']),
    },
};
</script>

<style lang="less" scoped>
ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style: none;
    flex-wrap: wrap;
    margin-top: 16px;
    li {
        margin: 0 2.4vw 2.4vw 0;
        border-radius: 4px;
        width: 20.53vw;
        height: 20.53vw;
        position: relative;
        overflow: hidden;
        img {
            border-radius: 4px;
        }
    }

    li:nth-child(4n) {
        margin-right: 0;
    }

    .banner-bg {
        display: block;
        width: 20.55vw;
        height: 20.55vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    .preview-img {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
}
</style>
